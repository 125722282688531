import { EndpointEnum } from 'enums/endpoint.enum';
import { get, post } from './http.client';
import { isResponseOk } from 'helpers/isResponseOk';
import { ILocation, LocationType } from '../interfaces/location/location.interface';
import { GOOGLE_MAP_API_KEY } from 'constants/env';
import axios from 'axios';

export const getLocationLink = async (name: string) => {
    try {
        const response = await post(`${EndpointEnum.LOCATIONS}/link`, {
            name,
            type: LocationType.FIND_DEVICE,
        });
        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (e) {
        console.error('error:', e);
    }
};

export type UpdateLocationProps = Required<Pick<ILocation, 'latitude' | 'longitude' | 'target'>>;

export const updateLocation = async (id: string, data: UpdateLocationProps) => {
    return post(`${EndpointEnum.LOCATIONS}/${id}`, data);
};

export const getLocation = async (locationId: string) => {
    try {
        const response = await get(`${EndpointEnum.LOCATIONS}/${locationId}`, {});
        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (e) {
        console.error('error:', e);
    }
};

export const getLocations = async () => {
    try {
        const response = await get(`${EndpointEnum.LOCATIONS}`, {});
        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (e) {
        console.error('error:', e);
    }
};

export const sendLocationSms = async (locationId: string, language: string) => {
    try {
        const response = await post(`${EndpointEnum.LOCATIONS}/${locationId}/sms`, { language, project: 'locatemate' });
        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (err: any) {
        console.error('error:', err);
        throw new Error(err.message);
    }
};

export const getCoordinatesByCityName = async (cityName: string) => {
    try {
        const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
            params: {
                address: cityName,
                key: GOOGLE_MAP_API_KEY,
            },
        });
        const { results } = response.data;
        if (results && results.length > 0) {
            const { lat, lng } = results[0].geometry.location;
            return { lat, lng };
        } else {
            throw new Error('No results found');
        }
    } catch (error) {
        console.error('Error fetching coordinates:', error);
        throw error;
    }
};
