export enum LocationStatus {
    PENDING = 'PENDING',
    SENT = 'SENT',
    SENDING_ERROR = 'SENDING_ERROR',
    API_LOCATED = 'API_LOCATED',
    GPS_LOCATED = 'GPS_LOCATED',
}

export enum LocationTarget {
    GPS = 'GPS',
    API = 'API',
}

export enum LocationType {
    FIND_DEVICE = 'find-device',
    LOST_PHONE = 'lost-phone',
}

export interface ILocation {
    id: string;
    name: string | null;
    userId: string;
    latitude?: string | null;
    longitude?: string | null;
    isEnable?: boolean;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    details: object | null;
    status: LocationStatus;
    target: LocationTarget | null;
    type: LocationType;
}
