import { loadFromSessionStorage, saveToSessionStorage } from 'services/storage';
import { LocalStorageKeys } from 'constants/LocalStorageKeys';
import { Countries, Languages } from 'enums/languages.enum';
import i18next from 'i18next';

export const setLanguage = (currentLanguage?: string) => {
    const language = currentLanguage || loadFromSessionStorage(LocalStorageKeys.LANGUAGE);
    if (language) {
        for (const key in Countries) {
            if (Countries[key].includes(language)) {
                return i18next.changeLanguage(key);
            }
        }
    }

    return i18next.changeLanguage('EN');
};

export const saveLanguage = (currentLanguage: string) => {
    const language = loadFromSessionStorage(LocalStorageKeys.LANGUAGE);
    if (language !== currentLanguage) {
        saveToSessionStorage(LocalStorageKeys.LANGUAGE, currentLanguage);
        setLanguage(currentLanguage);
    }
};

export const getLanguageOptions = Object.entries(Languages).map(([, value]) => {
    const language = {
        [Languages.en]: 'English',
        [Languages.fr]: 'French',
        [Languages.es]: 'Spanish',
        [Languages.pt]: 'Portuguese',
        [Languages.sa]: 'Arabic',
        [Languages.vn]: 'Vietnamese',
        [Languages.tr]: 'Turkish',
        [Languages.id]: 'Indonesian',
        [Languages.jp]: 'Japanese',
    };

    return { id: value, title: language[value] };
});
