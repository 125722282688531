/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull lt-react-app'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 2
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/kissmyapps/LT%20WEB/implementation/lt-react-app)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'default';

export const ApiKey: Record<Environment, string> = {
    default: '4cacea564ab2b5d0d62ff1ceef530d8b',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
    plan: {
        version: '2',
        branch: 'main',
        source: 'lt-react-app',
        versionId: 'ede4a766-c237-499f-a75e-fb42bab2658f',
    },
    ...{
        ingestionMetadata: {
            sourceName: 'browser-typescript-ampli',
            sourceVersion: '2.0.0',
        },
    },
};

export interface LoadOptionsBase {
    disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions } };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
    /**
     * **(af_ad) - передает креатив (paramKey: 'original_url_ad'**
     */
    ad?: string;
    /**
     * **(af_adset) - передает адсет (paramKey: 'no_adset'**
     */
    adGroupName?: string;
    /**
     * **("c" в ссылке onelink) - передает кампанию (paramKey: 'utm_campaign')**
     */
    campaignName?: string;
    /**
     * Number of a day in a year. Example: 1, 255, 365
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    cohort_date?: number;
    /**
     * Number of month in a year. Example: 1, 6, 12
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    cohort_month?: number;
    /**
     * Current week of the year
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    cohort_week?: number;
    /**
     * Current year
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    cohort_year?: number;
    initial_utm_campaign?: string;
    initial_utm_source?: string;
    /**
     * ASA/GoogleRedirect/FB/none(organic)
     *  **("pid" в ссылке onelink) - передает источник (paramKey: 'utm_source')**
     */
    network?: string;
    /**
     * **string название активной подписки в формате: weekly_9.99$/weekly_9.99$\_with_trial_3d**
     */
    product_id?: string;
    store_country?: string;
    subscription_active?: boolean;
    subscription_type?: string;
    user_email?: string;
    /**
     * utm_compaign
     */
    utm_campaign?: string;
    /**
     * utm_content
     */
    utm_content?: string;
    /**
     * utm_source
     */
    utm_source?: string;
    /**
     * utm_term
     */
    utm_term?: string;
}

export interface CheckoutFormClosedProperties {
    checkout_type: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    product_id?: string;
}

export interface CheckoutFormFilledProperties {
    checkout_type: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    product_id?: string;
}

export interface CheckoutFormShownProperties {
    checkout_type: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    product_id?: string;
}

export interface CheckoutFormSubmittedProperties {
    checkout_type: string;
    payment_method: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    product_id?: string;
}

export interface CollectGclidForTestProperties {
    gclid: string;
}

export interface LandingLocateClickProperties {
    country_code: string;
    entered_number: string;
    error_type: string;
    success: boolean;
}

export interface LandingScreenShownProperties {
    ab_applepay_2024_08_14?: string;
    ab_landing_2024_06_07?: string;
    ab_price_ID_MY_PH_IN_2024_06_07?: string;
    ab_price_SG_JP_TH_2024_06_07?: string;
}

export interface LoginLoginClickProperties {
    error_type: string;
    success: boolean;
    user_email: string;
}

export interface MysubsScreenShownProperties {
    subscription_active: boolean;
    subscription_type: string;
}

export interface NumberLocateClickedProperties {
    country_code: string;
    entered_number: string;
    error_type: string;
    success: boolean;
}

export interface PaymentErrorScreenShownProperties {
    error_type: string;
    payment_method: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    product_id?: string;
}

export interface SignupSignupClickedProperties {
    error_type: string;
    success: boolean;
    user_email: string;
}

export interface SubscriptionClosedProperties {
    type?: string;
}

export interface SubscriptionContinueClickedProperties {
  type: string;
}

export interface SubscriptionErrorShownProperties {
    error_type: string;
    payment_method: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    product_id?: string;
}

export interface SubscriptionShownProperties {
    flow: string;
    source?: string;
    type: string;
}

export interface SubscriptionSubscribeClickedProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    product_id?: string;
    type?: string;
}

export interface SubscriptionsShownProperties {
    path?: any;
}

export interface WebAttributionProperties {
    browser: string;
    browser_version: string;
    cohort_day: string;
    cohort_month: string;
    cohort_week: string;
    cohort_year: string;
    country: string;
    device_brand: string;
    device_category: string;
    device_id: string;
    device_language: string;
    device_model: string;
    fbclid: string;
    gclid: string;
    initial_fbclid: string;
    initial_gclid: string;
    initial_utm_ad?: string;
    initial_utm_campaign: string;
    initial_utm_content: string;
    initial_utm_medium: string;
    initial_utm_source: string;
    initial_utm_term: string;
    is_test?: boolean;
    operating_system: string;
    operating_system_version: string;
    project_name: string;
    user_id: string;
    utm_ad?: string;
    utm_campaign: string;
    utm_content: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    utm_medium: string;
    utm_source: string;
    utm_term: string;
}

export interface WebAttributionUpdateProperties {
    browser: string;
    browser_version: string;
    cohort_day: string;
    cohort_month: string;
    cohort_week: string;
    cohort_year: string;
    country: string;
    device_brand: string;
    device_category: string;
    device_id: string;
    device_language: string;
    device_model: string;
    fbclid: string;
    gclid: string;
    initial_fbclid: string;
    initial_gclid: string;
    initial_utm_ad?: string;
    initial_utm_campaign: string;
    initial_utm_content: string;
    initial_utm_medium: string;
    initial_utm_source: string;
    initial_utm_term: string;
    is_test?: boolean;
    operating_system: string;
    operating_system_version: string;
    project_name: string;
    user_id: string;
    utm_ad?: string;
    utm_campaign: string;
    utm_content: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    utm_medium: string;
    utm_source: string;
    utm_term: string;
}

export class Identify implements BaseEvent {
    event_type = amplitude.Types.SpecialEventType.IDENTIFY;

    constructor(public event_properties?: IdentifyProperties) {
        this.event_properties = event_properties;
    }
}

export class AccountLogoutClicked implements BaseEvent {
    event_type = 'account_logout_clicked';
}

export class CheckoutFormClosed implements BaseEvent {
    event_type = 'checkout_form_closed';

    constructor(public event_properties: CheckoutFormClosedProperties) {
        this.event_properties = event_properties;
    }
}

export class CheckoutFormFilled implements BaseEvent {
    event_type = 'checkout_form_filled';

    constructor(public event_properties: CheckoutFormFilledProperties) {
        this.event_properties = event_properties;
    }
}

export class CheckoutFormShown implements BaseEvent {
    event_type = 'checkout_form_shown';

    constructor(public event_properties: CheckoutFormShownProperties) {
        this.event_properties = event_properties;
    }
}

export class CheckoutFormSubmitted implements BaseEvent {
    event_type = 'checkout_form_submitted';

    constructor(public event_properties: CheckoutFormSubmittedProperties) {
        this.event_properties = event_properties;
    }
}

export class CollectGclidForTest implements BaseEvent {
    event_type = 'collect_gclid_for_test';

    constructor(public event_properties: CollectGclidForTestProperties) {
        this.event_properties = event_properties;
    }
}

export class LandingLocateClick implements BaseEvent {
    event_type = 'landing_locate_click';

    constructor(public event_properties: LandingLocateClickProperties) {
        this.event_properties = event_properties;
    }
}

export class LandingMenuClicked implements BaseEvent {
    event_type = 'landing_menu_clicked';
}

export class LandingScreenShown implements BaseEvent {
    event_type = 'landing_screen_shown';

    constructor(public event_properties?: LandingScreenShownProperties) {
        this.event_properties = event_properties;
    }
}

export class LandingScroll implements BaseEvent {
    event_type = 'landing_scroll';
}

export class LoginForgotClicked implements BaseEvent {
    event_type = 'login_forgot_clicked';
}

export class LoginLoginClick implements BaseEvent {
    event_type = 'login_login_click';

    constructor(public event_properties: LoginLoginClickProperties) {
        this.event_properties = event_properties;
    }
}

export class LoginScreenShown implements BaseEvent {
    event_type = 'login_screen_shown';
}

export class MessageExit implements BaseEvent {
    event_type = 'message_exit';
}

export class MessageSentSuccess implements BaseEvent {
    event_type = 'message_sent_success';
}

export class MysubsExtendClicked implements BaseEvent {
    event_type = 'mysubs_extend_clicked';
}

export class MysubsScreenShown implements BaseEvent {
    event_type = 'mysubs_screen_shown';

    constructor(public event_properties: MysubsScreenShownProperties) {
        this.event_properties = event_properties;
    }
}

export class MysubsStartTrialClicked implements BaseEvent {
    event_type = 'mysubs_start_trial_clicked';
}

export class NumberDetailsScreenShown implements BaseEvent {
    event_type = 'number_details_screen_shown';
}

export class NumberLocateClicked implements BaseEvent {
    event_type = 'number_locate_clicked';

    constructor(public event_properties: NumberLocateClickedProperties) {
        this.event_properties = event_properties;
    }
}

export class PaymentErrorScreenExit implements BaseEvent {
    event_type = 'payment_error_screen_exit';
}

export class PaymentErrorScreenShown implements BaseEvent {
    event_type = 'payment_error_screen_shown';

    constructor(public event_properties: PaymentErrorScreenShownProperties) {
        this.event_properties = event_properties;
    }
}

export class PaymentErrorTryAgainClicked implements BaseEvent {
    event_type = 'payment_error_try_again_clicked';
}

export class PaymentScreenExit implements BaseEvent {
    event_type = 'payment_screen_exit';
}

export class PaymentScreenShown implements BaseEvent {
    event_type = 'payment_screen_shown';
}

export class PaymentSuccessScreenShown implements BaseEvent {
    event_type = 'payment_success_screen_shown';
}

export class SignupScreenShown implements BaseEvent {
    event_type = 'signup_screen_shown';
}

export class SignupSignupClicked implements BaseEvent {
    event_type = 'signup_signup_clicked';

    constructor(public event_properties: SignupSignupClickedProperties) {
        this.event_properties = event_properties;
    }
}

export class SubscriptionClosed implements BaseEvent {
    event_type = 'subscription_closed';

    constructor(public event_properties?: SubscriptionClosedProperties) {
        this.event_properties = event_properties;
    }
}

export class SubscriptionContinueClicked implements BaseEvent {
  event_type = 'subscription_continue_clicked';

  constructor(
    public event_properties: SubscriptionContinueClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionErrorShown implements BaseEvent {
    event_type = 'subscription_error_shown';

    constructor(public event_properties: SubscriptionErrorShownProperties) {
        this.event_properties = event_properties;
    }
}

export class SubscriptionErrorTryAgainClicked implements BaseEvent {
    event_type = 'subscription_error_try_again_clicked';
}

export class SubscriptionShown implements BaseEvent {
    event_type = 'subscription_shown';

    constructor(public event_properties: SubscriptionShownProperties) {
        this.event_properties = event_properties;
    }
}

export class SubscriptionSubscribeClicked implements BaseEvent {
    event_type = 'subscription_subscribe_clicked';

    constructor(public event_properties?: SubscriptionSubscribeClickedProperties) {
        this.event_properties = event_properties;
    }
}

export class SubscriptionSuccess implements BaseEvent {
    event_type = 'subscription_success';
}

export class SubscriptionSuccessShown implements BaseEvent {
    event_type = 'subscription_success_shown';
}

export class SubscriptionsShown implements BaseEvent {
    event_type = 'subscriptions_shown';

    constructor(public event_properties?: SubscriptionsShownProperties) {
        this.event_properties = event_properties;
    }
}

export class WebAttribution implements BaseEvent {
    event_type = 'web_attribution';

    constructor(public event_properties: WebAttributionProperties) {
        this.event_properties = event_properties;
    }
}

export class WebAttributionUpdate implements BaseEvent {
    event_type = 'web_attribution_update';

    constructor(public event_properties: WebAttributionUpdateProperties) {
        this.event_properties = event_properties;
    }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * account_logout_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/account_logout_clicked)
   *
   * User logged out of the account
   *
   * @param options Amplitude event options.
   */
  accountLogoutClicked(
    options?: EventOptions,
  ) {
    return this.track(new AccountLogoutClicked(), options);
  }

  /**
   * checkout_form_closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/checkout_form_closed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. checkout_type)
   * @param options Amplitude event options.
   */
  checkoutFormClosed(
    properties: CheckoutFormClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutFormClosed(properties), options);
  }

  /**
   * checkout_form_filled
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/checkout_form_filled)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. checkout_type)
   * @param options Amplitude event options.
   */
  checkoutFormFilled(
    properties: CheckoutFormFilledProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutFormFilled(properties), options);
  }

  /**
   * checkout_form_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/checkout_form_shown)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. checkout_type)
   * @param options Amplitude event options.
   */
  checkoutFormShown(
    properties: CheckoutFormShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutFormShown(properties), options);
  }

  /**
   * checkout_form_submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/checkout_form_submitted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. checkout_type)
   * @param options Amplitude event options.
   */
  checkoutFormSubmitted(
    properties: CheckoutFormSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutFormSubmitted(properties), options);
  }

  /**
   * collect_gclid_for_test
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/collect_gclid_for_test)
   *
   * This event is triggered when a user's Google Click Identifier (gclid) is collected for testing purposes
   *
   * @param properties The event's properties (e.g. gclid)
   * @param options Amplitude event options.
   */
  collectGclidForTest(
    properties: CollectGclidForTestProperties,
    options?: EventOptions,
  ) {
    return this.track(new CollectGclidForTest(properties), options);
  }

  /**
   * landing_locate_click
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/landing_locate_click)
   *
   * User clicked "Locate button" on the landing page
   *
   * 1. country_code - varchar
   *
   * 2. entered_number - string
   *
   * 3. error_type - string
   *
   * 4. success - bool
   *
   * @param properties The event's properties (e.g. country_code)
   * @param options Amplitude event options.
   */
  landingLocateClick(
    properties: LandingLocateClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandingLocateClick(properties), options);
  }

  /**
   * landing_menu_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/landing_menu_clicked)
   *
   * User clicked menu icon via landing page
   *
   * @param options Amplitude event options.
   */
  landingMenuClicked(
    options?: EventOptions,
  ) {
    return this.track(new LandingMenuClicked(), options);
  }

  /**
   * landing_screen_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/landing_screen_shown)
   *
   * Landing page is shown
   *
   * @param properties The event's properties (e.g. ab_applepay_2024_08_14)
   * @param options Amplitude event options.
   */
  landingScreenShown(
    properties?: LandingScreenShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandingScreenShown(properties), options);
  }

  /**
   * landing_scroll
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/landing_scroll)
   *
   * Landing page scroll
   *
   * @param options Amplitude event options.
   */
  landingScroll(
    options?: EventOptions,
  ) {
    return this.track(new LandingScroll(), options);
  }

  /**
   * login_forgot_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/login_forgot_clicked)
   *
   * User clicked "Forgot password" on the login page
   *
   * @param options Amplitude event options.
   */
  loginForgotClicked(
    options?: EventOptions,
  ) {
    return this.track(new LoginForgotClicked(), options);
  }

  /**
   * login_login_click
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/login_login_click)
   *
   * User clicked login on the login page 
   *
   *
   * 1. user_email
   *
   * 2. error_type - string
   *
   * 3. success - bool
   *
   * @param properties The event's properties (e.g. error_type)
   * @param options Amplitude event options.
   */
  loginLoginClick(
    properties: LoginLoginClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginLoginClick(properties), options);
  }

  /**
   * login_screen_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/login_screen_shown)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  loginScreenShown(
    options?: EventOptions,
  ) {
    return this.track(new LoginScreenShown(), options);
  }

  /**
   * message_exit
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/message_exit)
   *
   * User decided not to send message and closed the view
   *
   * @param options Amplitude event options.
   */
  messageExit(
    options?: EventOptions,
  ) {
    return this.track(new MessageExit(), options);
  }

  /**
   * message_sent_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/message_sent_success)
   *
   * User clicked "Send" and message was sent to user
   *
   * @param options Amplitude event options.
   */
  messageSentSuccess(
    options?: EventOptions,
  ) {
    return this.track(new MessageSentSuccess(), options);
  }

  /**
   * mysubs_extend_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/mysubs_extend_clicked)
   *
   * User clicked Extend button to pay for a subcription
   *
   * @param options Amplitude event options.
   */
  mysubsExtendClicked(
    options?: EventOptions,
  ) {
    return this.track(new MysubsExtendClicked(), options);
  }

  /**
   * mysubs_screen_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/mysubs_screen_shown)
   *
   * User opened a page with subscriptions 
   *
   *  **sub_type, is_active ( на екрані показаний тип поточної підписки, або тріал (active, expired)**
   *
   * @param properties The event's properties (e.g. subscription_active)
   * @param options Amplitude event options.
   */
  mysubsScreenShown(
    properties: MysubsScreenShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new MysubsScreenShown(properties), options);
  }

  /**
   * mysubs_start_trial_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/mysubs_start_trial_clicked)
   *
   * User decided to start a trial
   *
   * @param options Amplitude event options.
   */
  mysubsStartTrialClicked(
    options?: EventOptions,
  ) {
    return this.track(new MysubsStartTrialClicked(), options);
  }

  /**
   * number_details_screen_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/number_details_screen_shown)
   *
   * User opened page with a detailed info about located number
   *
   * @param options Amplitude event options.
   */
  numberDetailsScreenShown(
    options?: EventOptions,
  ) {
    return this.track(new NumberDetailsScreenShown(), options);
  }

  /**
   * number_locate_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/number_locate_clicked)
   *
   * Autorized User clicked Locate Button, if it was succesfull, page "Send Message" is shown 
   *
   *  1. country_code - varchar
   *
   * 2. entered_number - string
   *
   * 3. error_type - string
   *
   * 4. success - bool
   *
   * @param properties The event's properties (e.g. country_code)
   * @param options Amplitude event options.
   */
  numberLocateClicked(
    properties: NumberLocateClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NumberLocateClicked(properties), options);
  }

  /**
   * payment_error_screen_exit
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/payment_error_screen_exit)
   *
   * User didnt clicked try again and closed page after the payment error
   *
   * @param options Amplitude event options.
   */
  paymentErrorScreenExit(
    options?: EventOptions,
  ) {
    return this.track(new PaymentErrorScreenExit(), options);
  }

  /**
   * payment_error_screen_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/payment_error_screen_shown)
   *
   * Payment error, user sees screen Oops/try again
   *
   * @param properties The event's properties (e.g. error_type)
   * @param options Amplitude event options.
   */
  paymentErrorScreenShown(
    properties: PaymentErrorScreenShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentErrorScreenShown(properties), options);
  }

  /**
   * payment_error_try_again_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/payment_error_try_again_clicked)
   *
   * User clicked Try again button after payment error
   *
   * @param options Amplitude event options.
   */
  paymentErrorTryAgainClicked(
    options?: EventOptions,
  ) {
    return this.track(new PaymentErrorTryAgainClicked(), options);
  }

  /**
   * payment_screen_exit
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/payment_screen_exit)
   *
   * User closed a payment page
   *
   * @param options Amplitude event options.
   */
  paymentScreenExit(
    options?: EventOptions,
  ) {
    return this.track(new PaymentScreenExit(), options);
  }

  /**
   * payment_screen_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/payment_screen_shown)
   *
   * Payment page is shown
   *
   * @param options Amplitude event options.
   */
  paymentScreenShown(
    options?: EventOptions,
  ) {
    return this.track(new PaymentScreenShown(), options);
  }

  /**
   * payment_success_screen_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/payment_success_screen_shown)
   *
   * Payment was successfull, and user sees page with payment confirm 
   *
   *  **amount(net/gross revenue), payment system , transactionid, original_transaction_id, sub_type**
   *
   * @param options Amplitude event options.
   */
  paymentSuccessScreenShown(
    options?: EventOptions,
  ) {
    return this.track(new PaymentSuccessScreenShown(), options);
  }

  /**
   * signup_screen_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/signup_screen_shown)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signupScreenShown(
    options?: EventOptions,
  ) {
    return this.track(new SignupScreenShown(), options);
  }

  /**
   * signup_signup_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/signup_signup_clicked)
   *
   * User filled in the fields and clicked Sign Up button
   *
   * 1. success - true/false - boolean 
   *
   * 2. error_type - string
   *
   * 3. user_email - string
   *
   * @param properties The event's properties (e.g. error_type)
   * @param options Amplitude event options.
   */
  signupSignupClicked(
    properties: SignupSignupClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignupSignupClicked(properties), options);
  }

  /**
   * subscription_closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/subscription_closed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  subscriptionClosed(
    properties?: SubscriptionClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionClosed(properties), options);
  }

  /**
   * subscription_continue_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/subscription_continue_clicked)
   *
   * **клік по кнопці контінує в мобільній версії. 
   *
   *  Важливо! Це не клік по кнопці Start trial**
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  subscriptionContinueClicked(
    properties: SubscriptionContinueClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionContinueClicked(properties), options);
  }

  /**
   * subscription_error_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/subscription_error_shown)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. error_type)
   * @param options Amplitude event options.
   */
  subscriptionErrorShown(
    properties: SubscriptionErrorShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionErrorShown(properties), options);
  }

  /**
   * subscription_error_try_again_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/subscription_error_try_again_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  subscriptionErrorTryAgainClicked(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionErrorTryAgainClicked(), options);
  }

  /**
   * subscription_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/subscription_shown)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. flow)
   * @param options Amplitude event options.
   */
  subscriptionShown(
    properties: SubscriptionShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionShown(properties), options);
  }

  /**
   * subscription_subscribe_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/subscription_subscribe_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. product_id)
   * @param options Amplitude event options.
   */
  subscriptionSubscribeClicked(
    properties?: SubscriptionSubscribeClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionSubscribeClicked(properties), options);
  }

  /**
   * subscription_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/subscription_success)
   *
   * This event is triggered when a user successfully completes a subscription process
   *
   * @param options Amplitude event options.
   */
  subscriptionSuccess(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionSuccess(), options);
  }

  /**
   * subscription_success_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/subscription_success_shown)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  subscriptionSuccessShown(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionSuccessShown(), options);
  }

  /**
   * subscriptions_shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/subscriptions_shown)
   *
   * Send event when subscription is shownThis event tracks when the subscriptions are displayed to users
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  subscriptionsShown(
    properties?: SubscriptionsShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionsShown(properties), options);
  }

  /**
   * web_attribution
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/web_attribution)
   *
   * First-time attribution event for web products
   *
   * @param properties The event's properties (e.g. browser)
   * @param options Amplitude event options.
   */
  webAttribution(
    properties: WebAttributionProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebAttribution(properties), options);
  }

  /**
   * web_attribution_update
   *
   * [View in Tracking Plan](https://data.amplitude.com/kissmyapps/LT%20WEB/events/main/latest/web_attribution_update)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. browser)
   * @param options Amplitude event options.
   */
  webAttributionUpdate(
    properties: WebAttributionUpdateProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebAttributionUpdate(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
